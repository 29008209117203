<template>
  <div class="olympic-detail" ref="olympicDetail">
    <!--火炬-->
    <div :class="['olympic-torch', { 'pc-olympic-torch': $Is_PC }]"
         @touchstart.prevent="btnTouchstart()" @touchend.prevent="btnTouchend()"
         @mousedown.prevent="btnTouchstart()"  @mouseup.prevent="btnTouchend()">
      <div class="boost-value">
        <p> 中国军团已获 </p>
        <p> <span class="num">{{ helpNum > 999999 ? (helpNum / 10000).toFixed(2) + 'w' : helpNum }}</span> 次加油</p>
      </div>
      <div class="fire-box">
        <div class="img" ref="fireImg"></div>
        <div class="boost-btn">
          点击加油
        </div>
      </div>
      <div ref="olympicBlastImg" class="blast-img-content">
      </div>
    </div>
    <div class="special-head" :class="[topOffset ? 'special-head-1' : ' ']">
      <van-sticky :class="[{ 'van-sticky-fixed': $Is_PC }]">
        <van-nav-bar @click-left="onClickLeft" @click-right="onClickRight">
          <template #left>
            <span class="iconfont ico">&#xe60b;</span>
          </template>
          <template #right>
            <span class="iconfont ico">&#xe684; </span>
          </template>
          <template #title v-if="topOffset">
            <span class="head-title">{{ olympicName }}</span>
          </template>
        </van-nav-bar>
      </van-sticky>
    </div>
    <div :class="['olympic-banner', { 'pc-olympic-banner': $Is_PC }]">
      <div class="olympic-hot-one"><span class="text">{{ hotNum > 999999 ? (hotNum / 10000).toFixed(2) + 'w' : hotNum }}热度</span></div>
      <img class="banner-bg-img" src="https://cdn.9kd.com/kdnet/olympic1.png" alt="" />
      <div class='font'></div>
      <div class='people'></div>
      <div class='cloud'></div>
      <div class="brief" v-if="description">
        <div class="description" v-html="description.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></div>
      </div>
    </div>
    <div class="olympic-main" ref="pronbit">
      <hot-point v-if="topicInfo[0] && flag && topicInfo[0].length > 1" :hotData="topicInfo[0]"></hot-point>
      <gold-rush :showWhat="true" v-if="topicInfo[1] && flag && topicInfo[1].length > 1" :goldData="topicInfo[1]"></gold-rush>
      <champion-person v-if="topicInfo[2] && flag && topicInfo[2].length > 1" :lists="topicInfo[2]" :goldNum="goldNum"></champion-person>
      <big-shot v-if="topicInfo[3] && flag && topicInfo[3].length > 1" :bigShotData="topicInfo[3]"></big-shot>
      <gold-rush :showWhat="false" v-if="topicInfo[4] && flag && topicInfo[4].length > 1" :goldData="topicInfo[4]"></gold-rush>
      <div class="olympic-hot" v-if="topicInfo[0] && topicInfo[1]">
        <img src="https://cdn.9kd.com/kdnet/olympic-bottom-fire.png" alt="" />
        <div class="text">热度{{ hotNum > 999999 ? (hotNum / 10000).toFixed(2) + 'w' : hotNum }}</div>
      </div>
    </div>
    <!-- 弹出层 -->
    <van-share-sheet @select="selectTag" v-model="showShare" :options="options" />
  </div>
</template>

<script>
export default {
  components: {
    'hot-point': () => import(/* webpackChunkName: "hotpoint" */ './HotPoint'),
    'gold-rush': () => import(/* webpackChunkName: "Gold-Rush" */ './GoldRush'),
    'champion-person': () => import(/* webpackChunkName: "Champion-Person" */ './ChampionPerson'),
    'big-shot': () => import(/* webpackChunkName: "hotpoint" */ './BigShot'),
  },
  props: ['id', 'channelId'],
  data() {
    return {
      num: 0, // 火炬点击次数
      topPicture: '', // 头部封面图
      helpNum: 0, // 助力值
      hotNum: 0, //热度值
      goldNum: 0, //金牌数
      flag: false,
      topicInfo: [],
      description: '', // 描述
      olympicName: '', // 专题名称
      active: 0,
      topOffset: false,
      showShare: false,
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: 'QQ', icon: 'qq' },
          { name: '微博', icon: 'weibo' }
        ],
      ],
      touchTime: 0,       // 长按时间
      interTimer: 0,      // 长按定时器
    }
  },
  created() {
    // 变化为奥运的样式
    this.$store.commit('changeDownloadCss',false)
    // 获取奥运专题数据
    this.getOlympicData()
    // 专题热度加一，返回当前专题总热度
    this.incrTopicHot()
  },
  async mounted() {
    // 滚动条的获取
    window.addEventListener('scroll', this.handleScroll, true)

    //  微信二次分享
    let params = `?channelId=${this.channelId}&topicId=${this.id}`
    let { data: res } = await this.$api.getOlympicData(params)
    let { data: share } = await this.$api.incrTopicShare(params)
    let sharedata = []
    if (res.code === 200) {
      sharedata = res.data
      sharedata.name = `我是${share.data.ranking}位助力奥运的人`
      sharedata.description = sharedata.list[0].title
      sharedata.firstPicture = sharedata.firstPicture ? res.data.firstPicture : this.$baseInfo.LOGO_IMG
    }
    // 微信二次分享，全局混入函数
    this.setWeChatShare({
      url: window.location.href,
      title: sharedata.name,
      description: sharedata.description,
      friendImg: sharedata.firstPicture || this.$baseInfo.LOGO_IMG,
      momentsImg: sharedata.firstPicture || this.$baseInfo.MOMENTS_LOGO_IMG
    });
  },
  beforeDestroy() {
    clearInterval(this.interTimer)
    window.removeEventListener('scroll', this.handleScroll, true)

  },
  methods: {
    // 点击助力
    async addHelp() {
      this.num++
      if (this.num === 8 && this.helpNum < 5000000) {
        let sun = this.helpNum / 10000
        let jynum = sun <= 10 ? 10 : (sun <= 20 ? 20 : (sun <= 50 ? 50 : (sun <= 100 ? 100 : 500)))
        this.$toast({
          message: '加油达到'+ jynum + '万有奖，关注凯迪网络微博',
          duration: 3000
        })
        this.num = 0
      }
      let params = {
        num: 1,
        topicId: this.id,
      }
      let { data: res } = await this.$api.addHelp(params)
      if (res.code !== 200) {
        this.$toast(res.msg)
      } else {
        this.helpNum++
      }
      this.blast()
    },
    /**
     * 爆炸效果
     * 1. 随机生成4-10个不一样图片
     * 2. 所有图片角度随机
     */
    blast(){
      this.$refs.fireImg.style = 'animation: scaleBigSmall 0.5s forwards;'

      let number = Math.floor(Math.random() * (10 - 4) + 4);
      let arr = [];
      arr = this.getRandom(arr,number,1);
      let url = 'https://cdn.9kd.com/kdnet/olympic/blast/';

      // 每一次点击都创建一个div包裹住当次的图片
      let imgDiv = document.createElement('div');
      let id = 'img' + Math.random().toString().slice(2);
      // 需要给定唯一的id
      imgDiv.id = id;

      // 添加进指定div
      this.$refs.olympicBlastImg.appendChild(imgDiv);

      // 创建多个img
      for (let i = 0; i < arr.length; i++) {
        let ele = document.createElement('img');
        ele.src = url + arr[i] + '.png';
        ele.className = `pos-img`;

        // 随机指定动画【1-20个动画】
        let animationId = Math.floor(Math.random() * (20 - 1) + 1);
        ele.style = `animation: posBlast${animationId} 0.6s ease-out forwards;`;

        // 添加进上面创建的div中
        this.$refs.olympicBlastImg.querySelector("#" + id).appendChild(ele);
      }
      // 600毫秒后删除当次创建的div
      this.removeBlastImg(id);
    },
    /**
     * arr 数组
     * number 数组长度个数
     * frequency 记录循环的次数，避免死循环
     */
    getRandom(arr,number,frequency){
      // 随机生成1-10的随机数
      let num = Math.floor(Math.random() * (10 - 1) + 1);
      // 判断循环的次数有没有大于数组的个数，大于的话直接添加进去
      if(frequency > number){
        arr.push(num);
      }
      // 数组没有达到
      if(arr.length < number){
        if(arr.length == 0){
          arr.push(num);
        }else {
          // 没有就添加
          if(!arr.includes(num)){
            arr.push(num);
            // 有就再循环一次，
          }else{
            frequency +=1;
            return this.getRandom(arr,number,frequency)
          }
        }
        frequency +=1;
        return this.getRandom(arr,number,frequency)

        // 达到要求的个数，直接返回数组
      }else{
        return arr
      }
    },
    /**
     * 移除图片
     */
    removeBlastImg(id){
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.$refs.fireImg.style = '';
        let parent = this.$refs.olympicBlastImg;
        let child = parent.querySelector("#" + id);
        parent.removeChild(child);
      }, 600);
    },
    /**
     * 手指按下事件
     */
    btnTouchstart(){
      this.touchTime = new Date().getTime();
      this.interTimer = setInterval(()=>{
        this.blast()
      },100)
    },
    /**
     * 手指按下结束事件
     */
    btnTouchend(){
      let date = new Date().getTime();
      if(date - this.touchTime < 100){
        clearInterval(this.interTimer)
      }
      if(this.interTimer){
        clearInterval(this.interTimer);
      }
      // 不管是长按还是短按，都只加一个助力值
      this.addHelp()
    },
    // 获取热度值、助力值，金牌数等
    async getAllOlympicNum() {
      let articleIds = []
      let arr = [...this.topicInfo[2]]
      arr.forEach((articalId) => {
        if (articalId.id) {
          articleIds.push(articalId.id)
        }
      })
      let params = {
        articleIds,
        channelId: this.channelId,
        topicId: this.id,
      }
      let { data: res } = await this.$api.getAllOlympicNum(params)
      if (res.code !== 200) this.$toast.error('请稍后重试')
      this.helpNum = res.data.assist
      this.hotNum = res.data.hot
      this.goldNum = res.data.total
      for (let key in res.data.applause) {
        arr.forEach((item) => {
          if (item.id == key) {
            item.cheer = res.data.applause[key]
          }
        })
      }
      this.topicInfo[2] = arr
    },
    // 获取奥运专题数据
    async getOlympicData() {
      let { data: res } = await this.$api.getOlympicData(`?channelId=${this.channelId}&topicId=${this.id}`)
      if (res.code === 200) {
        this.description = res.data.description
        this.olympicName = res.data.name
        this.topPicture = res.data.topPicture
        res.data.categoryList.forEach((item) => {
          let arr = []
          arr.push(item.categoryName)
          res.data.list.forEach((items) => {
            if (item.categoryId === items.tagId) {
              arr.push(items)
            }
          })
          this.topicInfo.push(arr)
        })
        this.getAllOlympicNum()
        this.flag = true
      } else {
        this.$router.push('/download')
      }
    },
    // 专题热度加一，返回当前专题总热度
    async incrTopicHot() {
      let { data: res } = await this.$api.incrTopicHot(`topicId=${this.id}`)
    },
    // 分享
    selectTag(tag) {
      if (tag.name === 'QQ') {
        this.qzone()
      } else if (tag.name === '微博') {
        this.weiShare()
      } else {
        let input = document.createElement('input');
        input.setAttribute('readonly', 'readonly');     // 防止调起键盘面板
        input.setAttribute('value', window.location.href);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');

        // document.body.appendChild(input);
        // document.body.insertAdjacentHTML('afterbegin', "<input style='position: absolute;opacity: 0' id='input' type='text' value='"+window.location.href+"'/>");
        // let inputElement = document.querySelector('#input');
        this.$dialog.alert({
          title: '链接已复制',
          message: '快去粘贴给你的好友吧！',
          theme: 'round-button',
          confirmButtonText: '我知道了'
        }).then(() => {
          document.body.removeChild(input);
        });
        /* this.$toast({
           type: 'html',
           message: '<p>使用浏览器的分享功能，把这</p><p style="text-align:left;">个专题分享出去</p>',
         })*/
      }
      this.showShare = false
    },
    // qq分享
    qzone() {
      let url = location.href
      var shareqqzonestring =
        'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url + '&title=' + this.olympicName + '&desc=' + '【分享来之凯迪网@凯迪网】' + '&summary=&site=&pics='
      window.open(shareqqzonestring)
      window.open(shareqqzonestring, 'newwindow', 'height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
    },
    weiShare() {
      let url = location.href
      var sharesinastring = 'http://v.t.sina.com.cn/share/share.php?title=' + '凯迪网-' + this.olympicName + '&url=' + url + '&content=utf-8&sourceUrl=' + url + '&pic=' + ''
      window.open(sharesinastring, 'newwindow', 'height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
      return
      var param = {
        url: window.location.href,
        type: '3',
        appkey: '4129988944' /** 您申请的应用appkey,显示分享来源(可选)*/,
        title: '你好微博。。。' /** 分享的文字内容(可选，默认为所在页面的title)*/,
        pic: pic || '' /**分享图片的路径(可选)*/,
        ralateUid: '' /**关联用户的UID，分享微博会@该用户(可选)*/,
        rnd: new Date().valueOf(),
      }
      var temp = []
      for (var p in param) {
        temp.push(p + '=' + encodeURIComponent(param[p] || ''))
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&')
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400')
    },
    // 监听滚动条
    handleScroll() {
      if (this.$refs.pronbit.getBoundingClientRect().top < 30) {
        this.topOffset = true
      } else {
        this.topOffset = false
      }
    },

    onClickLeft() {
      this.$router.push({
        path: '/',
      })
    },

    onClickRight() {
      this.showShare = true
    }
  },
}
</script>

<style lang='less'>
.olympic-detail {
  font-family: PingFang SC, PingFang SC-Regular;
  font-size: 13px;
  .olympic-torch {
    display: flex;
    z-index: 10;
    position: fixed;
    top: 29%;
    width: 160px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #FF4A48;
    border-radius: 0 21px 0 21px;
    transition: .5s ease;
    transform-style: preserve-3d;
    perspective: 500px;
    .boost-value{
      padding: 10px 0 10px 10px;
      color: white;
      text-align: right;
      font-size: 13px;
      white-space: nowrap;
      .num{
        color: #FFE10D;
      }
    }
    .fire-box{
      position: relative;
      height: 50px;
      width: 50px;
      border-radius: 40%;
      background: #C62F00;
      .img{
        position: absolute;
        bottom: 18px;
        left: 7px;
        width: 38px;
        height: 52px;
        background: url(https://cdn.9kd.com/kdnet/olympic/fire.png) no-repeat;
        background-size: 100%;
        pointer-events: none;
      }
      .animation{
        animation: scaleBigSmall 0.5s forwards;
      }
      .boost-btn{
        position: absolute;
        bottom: 0;
        right: -5px;
        padding: 3px;
        color: #C62F00;
        white-space: nowrap;
        border-radius: 5px 5px 0 0;
        background: linear-gradient(0deg,#fdbd7e 0%, #ffd9ad 94%, #ffffff 100%);
      }
    }
    .blast-img-content{
      position: absolute;
      right: 25px;
      top: 10px;
      width: 10px;
      height: 10px;
      .pos-img{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        opacity: 1;
        width: 20px;
        pointer-events: none;
      }
    }
  }
  .pc-olympic-torch {
    left: 50%;
    margin-left: 50px;
  }
  .special-head {
    height: 46px;
    width: 100%;
    overflow: hidden;

    .van-nav-bar {
      background: rgba(0, 0, 0, 0);

      .ico {
        font-size: 25px;
        color: #fff;
      }
    }
  }

  .special-head-1 {
    height: 46px;
    width: 100%;
    overflow: hidden;

    .van-nav-bar {
      background: #fff;

      .ico {
        font-size: 25px;
        color: #3a3e45;
      }
    }
  }

  .olympic-banner {
    position: relative;
    z-index: -1;
    width: 100%;
    margin-top: -46px;
    background-size: 100%;
    min-height: 360px;
    .banner-bg-img {
      display: block;
      width: 100%;
      min-height: 360px;
      object-fit: cover;
    }
    .font {
      position: absolute;
      top: 47px;
      left: 50%;
      width: 188px;
      height: 79px;
      margin-left: -94px;
      z-index: 1;
      background: url("https://cdn.9kd.com/kdnet/oly-font.png") no-repeat center center;
      background-size: 100% 100%;
      opacity: 0;
      animation: backInDown 1.2s 0.5s forwards;
    }
    .people {
      position: absolute;
      top: 103px;
      left: 50%;
      width: 198px;
      height: 201px;
      margin-left: -104px;
      z-index: 0;
      background: url("https://cdn.9kd.com/kdnet/oly-people.png") no-repeat center center;
      background-size: 100% 100%;
      animation: backInUp 1s;
    }
    .cloud {
      position: absolute;
      top: 158px;
      width: 100%;
      height: 202.5px;
      z-index: 1;
      background: pink;
      background: url("https://cdn.9kd.com/kdnet/oly-cloud.png") no-repeat center center;
      background-size: 100% 100%;
    }
    .olympic-hot-one{
      z-index: 10;
      position: absolute;
      top: 57%;
      left: 10px;
      box-sizing: border-box;
      height: 30px;
      padding: 10px;
      opacity: 0.9;
      background: #ffffff;
      border-radius: 15px;
      line-height: 10px;
      .text{
        opacity: 1;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #f7321c;
      }
    }
    .brief {
      position: absolute;
      top: 69%;
      left: 10px;
      width: 355px;
      max-height: 100px;
      background: #def1fe;
      border-radius: 10px;
      box-shadow: 0px 0px 18px 0px rgba(55, 170, 251, 0.27) inset;
      box-sizing: border-box;
      padding: 21px 31px 18px 27px;
      z-index: 1;
      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        font-size: 13px;
        font-weight: 700;
        color: #1c46b7;
        line-height: 23px;
      }
    }
  }
  .pc-olympic-banner {
    .brief {
      width: calc(100% - 20px);
    }
  }

  .olympic-main {
    min-height: 1000px;
    background: #5298ec;
    padding-top: 16px;
    padding: 0 10px 61px 10px;
    box-sizing: border-box;
    .olympic-hot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% + 20px);
      margin-left: -10px;
      height: 54px;
      font-size: 22px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #f7321c;
      background-color: #e9edf2;
      img {
        width: 16px;
        height: 22px;
        margin-right: 7px;
      }
    }
  }
  .van-sticky-fixed .van-sticky {
    position: fixed;
    top: 0;
    left: calc(50% - 220px);
    width: 440px;
  }
}
@keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(.7);
    transform: translateY(-1200px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}
@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(.7);
    transform: translateY(1200px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}
@keyframes posBlast1{
  70% {
    opacity: 1;
  }
  100% {
    top: -100px;
    left: -100px;
    opacity: 0;
  }
}
@keyframes posBlast2{
  70% {
    opacity: 1;
  }
  100% {
    left: -130px;
    top: 0;
    opacity: 0;
  }
}
@keyframes posBlast3{
  70% {
    opacity: 1;
  }
  100% {
    left: -183px;
    top: -167px;
    opacity: 0;
  }
}
@keyframes posBlast4{
  70% {
    opacity: 1;
  }
  100% {
    left: 0;
    top: -120px;
    opacity: 0;
  }
}
@keyframes posBlast5{
  70% {
    opacity: 1;
  }
  100% {
    left: 30px;
    top: -170px;
    opacity: 0;
  }
}
@keyframes posBlast6{
  70% {
    opacity: 1;
  }
  100% {
    left: 100px;
    top: -80px;
    opacity: 0;
  }
}

@keyframes posBlast7{
  70% {
    opacity: 1;
  }
  100% {
    left: -119px;
    top: -167px;
    opacity: 0;
  }
}
@keyframes posBlast8{
  70% {
    opacity: 1;
  }
  100% {
    left: -52px;
    top: -213px;
    opacity: 0;
  }
}
@keyframes posBlast9{
  70% {
    opacity: 1;
  }
  100% {
    left: 81px;
    top: -130px;
    opacity: 0;
  }
}
@keyframes posBlast10{
  70% {
    opacity: 1;
  }
  100% {
    left: 48px;
    top: -218px;
    opacity: 0;
  }
}

@keyframes posBlast11{
  70% {
    opacity: 1;
  }
  100% {
    top: -100px;
    left: -100px;
    opacity: 0;
  }
}
@keyframes posBlast12{
  70% {
    opacity: 1;
  }
  100% {
    left: -56px;
    top: -139px;
    opacity: 0;
  }
}
@keyframes posBlast13{
  70% {
    opacity: 1;
  }
  100% {
    left: -282px;
    top: -115px;
    opacity: 0;
  }
}
@keyframes posBlast14{
  70% {
    opacity: 1;
  }
  100% {
    left: 0;
    top: -242px;
    opacity: 0;

  }
}
@keyframes posBlast15{
  70% {
    opacity: 1;
  }
  100% {
    left: -106px;
    top: -208px;
    opacity: 0;

  }
}
@keyframes posBlast16{
  70% {
    opacity: 1;
  }
  100% {
    left: -303px;
    top: -161px;
    opacity: 0;
  }
}

@keyframes posBlast17{
  70% {
    opacity: 1;
  }
  100% {
    left: -209px;
    top: -177px;
    opacity: 0;

  }
}
@keyframes posBlast18{
  70% {
    opacity: 1;
  }
  100% {
    left: -85px;
    top: -28px;
    opacity: 0;

  }
}
@keyframes posBlast19{
  70% {
    opacity: 1;
  }
  100% {
    left: 72px;
    top: -84px;
    opacity: 0;

  }
}
@keyframes posBlast20{
  70% {
    opacity: 1;
  }
  100% {
    left: -103px;
    top: -57px;
    opacity: 0;

  }
}

@keyframes scaleBigSmall {
  0%{
    transform: scale(1);
  }
  30%{
    transform: scale(0.9);
  }
  70%{
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);

  }
}

</style>
